import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="code_detail">
        <div className="code_detail_inner">
          <div className="code_top">
            <h1 className="privacy_h1">
              <mark className="ud ue ao">Medium Privacy Policy</mark>
            </h1>
            <h2 className="privacy_h2">Effective date: March 24, 2022</h2>
          </div>

          <div className="code_sec">
            <div className="code_sec_top">
              <div className="code_sec_top_left">
                <img
                  alt=""
                  src="https://miro.medium.com/v2/resize:fill:44:44/1*4eBYLmk70ln9psBY4_bL9A.jpeg"
                />
              </div>
              <div className="code_sec_top_right">
                <p>Medium</p>
                <p>Published in Medium Policy · 11 min read · Sep 2, 2020</p>
              </div>
            </div>
          </div>

          <div className="code_aey">
            <div className="aey_left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-label="clap"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.37.828 12 3.282l.63-2.454zM13.916 3.953l1.523-2.112-1.184-.39zM8.589 1.84l1.522 2.112-.337-2.501zM18.523 18.92c-.86.86-1.75 1.246-2.62 1.33a6 6 0 0 0 .407-.372c2.388-2.389 2.86-4.951 1.399-7.623l-.912-1.603-.79-1.672c-.26-.56-.194-.98.203-1.288a.7.7 0 0 1 .546-.132c.283.046.546.231.728.5l2.363 4.157c.976 1.624 1.141 4.237-1.324 6.702m-10.999-.438L3.37 14.328a.828.828 0 0 1 .585-1.408.83.83 0 0 1 .585.242l2.158 2.157a.365.365 0 0 0 .516-.516l-2.157-2.158-1.449-1.449a.826.826 0 0 1 1.167-1.17l3.438 3.44a.363.363 0 0 0 .516 0 .364.364 0 0 0 0-.516L5.293 9.513l-.97-.97a.826.826 0 0 1 0-1.166.84.84 0 0 1 1.167 0l.97.968 3.437 3.436a.36.36 0 0 0 .517 0 .366.366 0 0 0 0-.516L6.977 7.83a.82.82 0 0 1-.241-.584.82.82 0 0 1 .824-.826c.219 0 .43.087.584.242l5.787 5.787a.366.366 0 0 0 .587-.415l-1.117-2.363c-.26-.56-.194-.98.204-1.289a.7.7 0 0 1 .546-.132c.283.046.545.232.727.501l2.193 3.86c1.302 2.38.883 4.59-1.277 6.75-1.156 1.156-2.602 1.627-4.19 1.367-1.418-.236-2.866-1.033-4.079-2.246M10.75 5.971l2.12 2.12c-.41.502-.465 1.17-.128 1.89l.22.465-3.523-3.523a.8.8 0 0 1-.097-.368c0-.22.086-.428.241-.584a.847.847 0 0 1 1.167 0m7.355 1.705c-.31-.461-.746-.758-1.23-.837a1.44 1.44 0 0 0-1.11.275c-.312.24-.505.543-.59.881a1.74 1.74 0 0 0-.906-.465 1.47 1.47 0 0 0-.82.106l-2.182-2.182a1.56 1.56 0 0 0-2.2 0 1.54 1.54 0 0 0-.396.701 1.56 1.56 0 0 0-2.21-.01 1.55 1.55 0 0 0-.416.753c-.624-.624-1.649-.624-2.237-.037a1.557 1.557 0 0 0 0 2.2c-.239.1-.501.238-.715.453a1.56 1.56 0 0 0 0 2.2l.516.515a1.556 1.556 0 0 0-.753 2.615L7.01 19c1.32 1.319 2.909 2.189 4.475 2.449q.482.08.971.08c.85 0 1.653-.198 2.393-.579.231.033.46.054.686.054 1.266 0 2.457-.52 3.505-1.567 2.763-2.763 2.552-5.734 1.439-7.586z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="agg"
              >
                <path d="M18.006 16.803c1.533-1.456 2.234-3.325 2.234-5.321C20.24 7.357 16.709 4 12.191 4S4 7.357 4 11.482c0 4.126 3.674 7.482 8.191 7.482.817 0 1.622-.111 2.393-.327.231.2.48.391.744.559 1.06.693 2.203 1.044 3.399 1.044.224-.008.4-.112.486-.287a.49.49 0 0 0-.042-.518c-.495-.67-.845-1.364-1.04-2.057a4 4 0 0 1-.125-.598zm-3.122 1.055-.067-.223-.315.096a8 8 0 0 1-2.311.338c-4.023 0-7.292-2.955-7.292-6.587 0-3.633 3.269-6.588 7.292-6.588 4.014 0 7.112 2.958 7.112 6.593 0 1.794-.608 3.469-2.027 4.72l-.195.168v.255c0 .056 0 .151.016.295.025.231.081.478.154.733.154.558.398 1.117.722 1.659a5.3 5.3 0 0 1-2.165-.845c-.276-.176-.714-.383-.941-.59z"></path>
              </svg>
            </div>
            <div className="aey_right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="aq"
              >
                <path
                  fill="#000"
                  d="M17.5 1.25a.5.5 0 0 1 1 0v2.5H21a.5.5 0 0 1 0 1h-2.5v2.5a.5.5 0 0 1-1 0v-2.5H15a.5.5 0 0 1 0-1h2.5zm-11 4.5a1 1 0 0 1 1-1H11a.5.5 0 0 0 0-1H7.5a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-8.5a.5.5 0 0 0-1 0v7.48l-5.2-4a.5.5 0 0 0-.6 0l-5.2 4z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0m9-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m3.376 10.416-4.599 3.066a.5.5 0 0 1-.777-.416V8.934a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M15.218 4.931a.4.4 0 0 1-.118.132l.012.006a.45.45 0 0 1-.292.074.5.5 0 0 1-.3-.13l-2.02-2.02v7.07c0 .28-.23.5-.5.5s-.5-.22-.5-.5v-7.04l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.79a.42.42 0 0 1 .068.498m-.106.138.008.004v-.01zM16 7.063h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11c-1.1 0-2-.9-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.15.5.5 0 0 1 .15.35.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9v-10.2c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M4.385 12c0 .55.2 1.02.59 1.41.39.4.86.59 1.41.59s1.02-.2 1.41-.59c.4-.39.59-.86.59-1.41s-.2-1.02-.59-1.41a1.93 1.93 0 0 0-1.41-.59c-.55 0-1.02.2-1.41.59-.4.39-.59.86-.59 1.41m5.62 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.42.59s1.02-.2 1.41-.59c.4-.39.59-.86.59-1.41s-.2-1.02-.59-1.41a1.93 1.93 0 0 0-1.41-.59c-.55 0-1.03.2-1.42.59s-.58.86-.58 1.41m5.6 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.43.59s1.03-.2 1.42-.59.58-.86.58-1.41-.2-1.02-.58-1.41a1.93 1.93 0 0 0-1.42-.59c-.56 0-1.04.2-1.43.59s-.58.86-.58 1.41"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="privacy_body">
            <p className="first_privacy_p">
              <strong>
                You can see our previous Privacy Policy{" "}
                <Link to="/privacy">here</Link>.
              </strong>
            </p>
            <p>
              This Privacy Policy explains how A Medium Corporation (
              <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">Medium</em>
              </strong>
              <em className="oe">,”</em> <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">we</em>
              </strong>
              <em className="oe">,”</em> or <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">us</em>
              </strong>
              <em className="oe">”</em>) collects, uses, and discloses
              information about you. This Privacy Policy applies when you use
              our websites, mobile applications, and other online products and
              services that link to this Privacy Policy (collectively, our{" "}
              <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">Services</em>
              </strong>
              ”), contact our customer service team, engage with us on social
              media, or otherwise interact with us.
            </p>
            <p id="5e69">
              We may change this Privacy Policy from time to time. If we make
              changes, we will notify you by revising the date at the top of
              this policy and, in some cases, we may provide you with additional
              notice (such as adding a statement to our website or providing you
              with a notification). We encourage you to review this Privacy
              Policy regularly to stay informed about our information practices
              and the choices available to you.
            </p>
            <h1>CONTENTS</h1>
            <ul>
              <li>Collection of Information</li>
              <li>Use of Information</li>
              <li>Sharing of Information</li>
              <li>Third-Party Embeds</li>
              <li>
                Transfer of Information to the United States and Other Countries
              </li>
              <li>Your Choices</li>
              <li>Your California Privacy Rights</li>
              <li>Additional Disclosures for Individuals in Europe</li>
              <li>Contact Us</li>
            </ul>
            <h2>COLLECTION OF INFORMATION</h2>
            <h2>Information You Provide to Us</h2>
            <p>
              We collect information you provide directly to us. For example,
              you share information directly with us when you create an account,
              fill out a form, submit or post content through our Services,
              purchase a membership, communicate with us via third-party
              platforms, request customer support, or otherwise communicate with
              us. The types of personal information we may collect include your
              name, display name, username, bio, email address, business
              information, your content, including your avatar image, photos,
              posts, responses, and series published by you, and any other
              information you choose to provide.
            </p>
            <p>
              In some cases, we may also collect information you provide about
              others, such as when you purchase a Medium membership as a gift
              for someone. We will use this information to fulfill your request
              and will not send communications to your contacts unrelated to
              your request, unless they separately consent to receive
              communications from us or otherwise engage with us.
            </p>
            <p>
              We do not collect payment information through our Services. We
              rely on third parties to process payments in connection with our
              Services. Any information you provide to facilitate such a payment
              is subject to the third-party payment processor’s privacy policy,
              and we encourage you to review this policy before you provide any
              information to the payment processor.
            </p>
            <h2>
              Information We Collect Automatically When You Interact with Us
            </h2>
            <p>
              In some instances, we automatically collect certain information,
              including:
            </p>
            <ul>
              <li>
                <strong className="nk gv">Activity Information:</strong> We
                collect information about your activity on our Services, such as
                your reading history and when you share links, follow users,
                highlight posts, and clap for posts.
              </li>
              <li id="fb46">
                <strong className="nk gv">Transactional Information:</strong>{" "}
                When you purchase a membership, we collect information about the
                transaction, such as subscription details, purchase price, and
                the date of the transaction.
              </li>
              <li id="ba78">
                <strong className="nk gv">Device and Usage Information:</strong>{" "}
                We collect information about how you access our Services,
                including data about the device and network you use, such as
                your hardware model, operating system version, mobile network,
                IP address, unique device identifiers, browser type, and app
                version. We also collect information about your activity on our
                Services, such as access times, pages viewed, links clicked, and
                the page you visited before navigating to our Services.
              </li>
              <li id="cb17">
                <strong className="nk gv">
                  Information Collected by Cookies and Similar Tracking
                  Technologies:
                </strong>{" "}
                We use tracking technologies, such as cookies and web beacons,
                to collect information about you. Cookies are small data files
                stored on your hard drive or in device memory that help us
                improve our Services and your experience, see which areas and
                features of our Services are popular, and count visits. Web
                beacons (also known as “pixel tags” or “clear GIFs”) are
                electronic images that we use on our Services and in our emails
                to help deliver cookies, count visits, and understand usage. We
                also work with third party analytics providers who use cookies,
                web beacons, device identifiers, and other technologies to
                collect information about your use of our Services and other
                websites and applications, including your IP address, web
                browser, mobile network information, pages viewed, time spent on
                pages or in mobile apps, and links clicked. This information may
                be used by Medium and others to, among other things, analyze and
                track data, determine the popularity of certain content, deliver
                content targeted to your interests on our Services, and better
                understand your online activity. For more information about
                cookies and how to disable them, see Your Choices below.
              </li>
            </ul>
            <h2>Information We Collect from Other Sources</h2>
            <p id="1794">
              We obtain information from third-party sources. For example, we
              may collect information about you from social networks, accounting
              services providers and data analytics providers. Additionally, if
              you create or log into your Medium account through a third-party
              platform (such as Apple, Facebook, Google, or Twitter), we will
              have access to certain information from that platform, such as
              your name, lists of friends or followers, birthday, and profile
              picture, in accordance with the authorization procedures
              determined by such platform.
            </p>
            <h2>Information We Derive</h2>
            <p>
              We may derive information or draw inferences about you based on
              the information we collect. For example, we may make inferences
              about your location based on your IP address or infer reading
              preferences based on your reading history.
            </p>
            <h2>USE OF INFORMATION</h2>
            <p>
              We use the information we collect to provide, maintain, and
              improve our Services, which includes publishing and distributing
              user-generated content, personalizing the posts you see and
              operating our metered paywall. We also use the information we
              collect to:
            </p>
            <ul>
              <li id="58d7">Create and maintain your Medium account;</li>
              <li id="8404">
                Process transactions and send related information, such as
                confirmations, receipts, and user experience surveys;
              </li>
              <li id="b238">
                Send you technical notices, security alerts, and support and
                administrative messages;
              </li>
              <li id="3aa3">
                Respond to your comments and questions and provide customer
                service;
              </li>
              <li id="6ba1">
                Communicate with you about new content, products, services, and
                features offered by Medium and provide other news and
                information we think will interest you (see Your Choices below
                for information about how to opt out of these communications at
                any time);
              </li>
              <li id="50db">
                Monitor and analyze trends, usage, and activities in connection
                with our Services;
              </li>
              <li id="7fb4">
                Detect, investigate, and prevent security incidents and other
                malicious, deceptive, fraudulent, or illegal activity and
                protect the rights and property of Medium and others;
              </li>
              <li id="427e">
                Debug to identify and repair errors in our Services;
              </li>
              <li id="5899">
                Comply with our legal and financial obligations; and
              </li>
              <li id="b5fe">
                Carry out any other purpose described to you at the time the
                information was collected.
              </li>
            </ul>
            <h2>SHARING OF INFORMATION</h2>
            <p>
              We share personal information in the following circumstances or as
              otherwise described in this policy:
            </p>
            <ul>
              <li id="35ed">
                We share personal information with other users of the Services.
                For example, if you use our Services to publish content, post
                comments or send private notes, certain information about you
                will be visible to others, such as your name, photo, bio, other
                account information you may provide, and information about your
                activities on our Services (e.g., your followers and who you
                follow, recent posts, claps, highlights, and responses).
              </li>
              <li id="a9f8">
                We share personal information with vendors, service providers,
                and consultants that need access to personal information in
                order to perform services for us, such as companies that assist
                us with web hosting, storage, and other infrastructure,
                analytics, payment processing, fraud prevention and security,
                customer service, communications, and marketing.
              </li>
              <li id="710d">
                We may disclose personal information if we believe that
                disclosure is in accordance with, or required by, any applicable
                law or legal process, including lawful requests by public
                authorities to meet national security or law enforcement
                requirements. If we are going to disclose your personal
                information in response to legal process, we will give you
                notice so you can challenge it (for example by seeking court
                intervention), unless we are prohibited by law or believe doing
                so may endanger others or cause illegal conduct. We will object
                to legal requests for information about users of our Services
                that we believe are improper.
              </li>
              <li id="8fad">
                We may share personal information if we believe that your
                actions are inconsistent with our{" "}
                <a href="/">user agreements or policies</a>, if we believe that
                you have violated the law, or if we believe it is necessary to
                protect the rights, property, and safety of Medium, our users,
                the public, or others.
              </li>
              <li id="048c">
                We share personal information with our lawyers and other
                professional advisors where necessary to obtain advice or
                otherwise protect and manage our business interests.
              </li>
              <li id="32ea">
                We may share personal information in connection with, or during
                negotiations concerning, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business by
                another company.
              </li>
              <li id="29dd">
                Personal information is shared between and among Medium and our
                current and future parents, affiliates, and subsidiaries and
                other companies under common control and ownership.
              </li>
              <li id="ec25">
                We share personal information with your consent or at your
                direction.
              </li>
              <li id="ec6a">
                We also share aggregated or de-identified information that
                cannot reasonably be used to identify you.
              </li>
            </ul>
            <h2>THIRD-PARTY EMBEDS</h2>
            <p id="9ed7">
              Medium does not host some of the content displayed on our
              Services. Users have the ability to post content that is actually
              hosted by a third party, but is embedded in our pages (an{" "}
              <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">Embed</em>
              </strong>
              <em className="oe">”</em>). When you interact with an Embed, it
              can send information about your interaction to the hosting third
              party just as if you were visiting the third party’s site
              directly. For example, when you load a Medium post page with a
              YouTube video Embed and watch the video, YouTube receives
              information about your activity, such as your IP address and how
              much of the video you watch. Medium does not control what
              information third parties collect through Embeds or what they do
              with the information. This Privacy Policy does not apply to
              information collected through Embeds. The privacy policy belonging
              to the third party hosting the Embed applies to any information
              the Embed collects, and we recommend you review that policy before
              interacting with the Embed.
            </p>
            <h2>
              TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
            </h2>
            <p id="3e7d">
              Medium is headquartered in the United States, and we have
              operations and service providers in the United States and other
              countries. Therefore, we and our service providers may transfer
              your personal information to, or store or access it in,
              jurisdictions that may not provide levels of data protection that
              are equivalent to those of your home jurisdiction. For example, we
              transfer personal data to Amazon Web Services, one of our service
              providers that processes personal information for us in various
              data center locations across the globe, including those listed{" "}
              <a href="/">here</a>. We will take steps to ensure that your
              personal information receives an adequate level of protection in
              the jurisdictions in which we process it.
            </p>
            <h2>YOUR CHOICES</h2>
            <h2>Account Information</h2>
            <p id="9410">
              You may access, correct, delete and export your account
              information at any time by logging into the Services and
              navigating to the
              <a href="/">Settings page</a>. Please note that if you choose to
              delete your account, we may continue to retain certain information
              about you as required by law or for our legitimate business
              purposes.
            </p>
            <h2>Cookies</h2>
            <p>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can usually adjust your browser settings to remove or
              reject browser cookies. Please note that removing or rejecting
              cookies could affect the availability and functionality of our
              Services.
            </p>
            <h2>Communications Preferences</h2>
            <p id="0688">
              You may opt out of receiving certain communications from us, such
              as digests, newsletters, and activity notifications, by following
              the instructions in those communications or through your account’s{" "}
              <a href="/">Settings page</a>. If you opt out, we may still send
              you administrative emails, such as those about your account or our
              ongoing business relations.
            </p>
            <h2>Mobile Push Notifications</h2>
            <p>
              With your consent, we may send push notifications to your mobile
              device. You can deactivate these messages at any time by changing
              the notification settings on your mobile device.
            </p>
            <h2>YOUR CALIFORNIA PRIVACY RIGHTS</h2>
            <p id="3764">
              The California Consumer Privacy Act or <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">CCPA</em>
              </strong>
              <em className="oe">”</em> (Cal. Civ. Code § 1798.100 et seq.)
              affords consumers residing in California certain rights with
              respect to their personal information. If you are a California
              resident, this section applies to you.
            </p>
            <p>
              In the preceding 12 months, we have collected the following
              categories of personal information: identifiers, commercial
              information, internet or other electronic network activity
              information, and inferences. For details about the precise data
              points we collect and the categories of sources of such
              collection, please see the Collection of Information section
              above. We collect personal information for the business and
              commercial purposes described in the Use of Information section
              above. In the preceding 12 months, we have disclosed the following
              categories of personal information for business purposes to the
              following categories of recipients:
            </p>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <img
                alt=""
                src="	https://miro.medium.com/v2/resize:fit:720/format:webp/0*b9fTh2C5b0m9HrZm"
              />
            </div>
            <p id="7cf0">
              <a href="/">Link to a text version of this table</a>
            </p>
            <p>Medium does not sell your personal information.</p>
            <p id="efcc">
              Subject to certain limitations, you have the right to (1) request
              to know more about the categories and specific pieces of personal
              information we collect, use, and disclose about you, (2) request
              deletion of your personal information, (3) opt out of any sales of
              your personal information, if we engage in that activity in the
              future, and (4) not be discriminated against for exercising these
              rights. You may make these requests by emailing us at{" "}
              <a href="mailto:service@gnair.cfd">service@gnair.cfd</a> or by
              completing <a href="/">this webform</a>. We will verify a webform
              request by asking you to provide identifying information. We will
              not discriminate against you if you exercise your rights under the
              CCPA.
            </p>
            <p id="87aa">
              If we receive your request from an authorized agent, we may ask
              for evidence that you have provided such agent with a power of
              attorney or that the agent otherwise has valid written authority
              to submit requests to exercise rights on your behalf. This may
              include requiring you to verify your identity. If you are an
              authorized agent seeking to make a request, please{" "}
              <a href="mailto:service@gnair.cfd">contact us</a>.
            </p>
            <h2>ADDITIONAL DISCLOSURES FOR INDIVIDUALS IN EUROPE</h2>
            <p id="ad9c">
              If you are located in the European Economic Area (
              <em className="oe">“</em>
              <strong className="nk gv">
                <em className="oe">EEA</em>
              </strong>
              <em className="oe">”</em>), the United Kingdom, or Switzerland,
              you have certain rights and protections under applicable law
              regarding the processing of your personal data, and this section
              applies to you.
            </p>
            <h2>Legal Basis for Processing</h2>
            <p>
              When we process your personal data, we will do so in reliance on
              the following lawful bases:
            </p>
            <ul>
              <li id="8f48">
                To perform our responsibilities under our contract with you
                (e.g., providing the products and services you requested).
              </li>
              <li id="1006">
                When we have a legitimate interest in processing your personal
                data to operate our business or protect our interests (e.g., to
                provide, maintain, and improve our products and services,
                conduct data analytics, and communicate with you).
              </li>
              <li id="4341">
                To comply with our legal obligations (e.g., to maintain a record
                of your consents and track those who have opted out of
                non-administrative communications).
              </li>
              <li id="a359">
                When we have your consent to do so (e.g., when you opt in to
                receive non-administrative communications from us). When consent
                is the legal basis for our processing your personal data, you
                may withdraw such consent at any time.
              </li>
            </ul>
            <h2>Data Retention</h2>
            <p>
              We store personal data associated with your account for as long as
              your account remains active. If you close your account, we will
              delete your account data within 14 days. We store other personal
              data for as long as necessary to carry out the purposes for which
              we originally collected it and for other legitimate business
              purposes, including to meet our legal, regulatory, or other
              compliance obligations.
            </p>
            <h2>Data Subject Requests</h2>
            <p>
              Subject to certain limitations, you have the right to request
              access to the personal data we hold about you and to receive your
              data in a portable format, the right to ask that your personal
              data be corrected or erased, and the right to object to, or
              request that we restrict, certain processing. To exercise your
              rights:
            </p>
            <ul>
              <li id="1003">
                If you sign up for a Medium account, you may at any time request
                an export of your personal information from the{" "}
                <a href="/" rel="noopener">
                  Settings page
                </a>
                , or by going to Settings and then selecting Account within our
                app.
              </li>
              <li id="c110">
                You may correct information associated with your account from
                the{" "}
                <a href="/" rel="noopener">
                  Settings page
                </a>
                , or by going to Settings and then selecting Account within our
                app, and the{" "}
                <a href="/" rel="noopener">
                  Customize Your Interests page
                </a>{" "}
                to update your interests.
              </li>
              <li id="3537">
                You may withdraw consent by deleting your account at any time
                through the{" "}
                <a href="/" rel="noopener">
                  Settings page
                </a>
                , or by going to Settings and then selecting Account within our
                app (except to the extent Medium is prevented by law from
                deleting your information).
              </li>
              <li id="f494">
                You may object at any time to the use of your personal data by
                contacting{" "}
                <a href="mailto:service@gnair.cfd">service@gnair.cfd</a>.
              </li>
            </ul>
            <h2>Questions or Complaints</h2>
            <p>
              If you have a concern about our processing of personal data that
              we are not able to resolve, you have the right to lodge a
              complaint with the Data Protection Authority where you reside.
              Contact details for your Data Protection Authority can be found
              using the links below:
            </p>
            <ul>
              <li id="d4b3">
                For individuals in the EEA:
                <br />
                <a href="/">
                  https://edpb.europa.eu/about-edpb/board/members_en
                </a>
              </li>
              <li id="b1d6">
                For individuals in the UK:
                <br />
                <a href="/">https://ico.org.uk/global/contact-us/</a>
              </li>
              <li id="f824">
                For individuals in Switzerland:{" "}
                <a href="/">
                  https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
                </a>
              </li>
            </ul>
            <h2>CONTACT US</h2>
            <p id="8a39">
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <a href="mailto:service@gnair.cfd">service@gnair.cfd</a>.
            </p>
            <p id="632e">
              If you are from the EEA or the United Kingdom and have questions
              about this Privacy Policy, please contact us at{" "}
              <a href="mailto:service@gnair.cfd">service@gnair.cfd</a> or our
              privacy representatives as follows:
            </p>
            <h2>Privacy representative for EEA</h2>
            <p id="ebaf">
              Unit 3D North Point House
              <br />
              North Point Business Park
              <br />
              New Mallow Road
              <br />
              Cork T23AT2P
              <br />
              Ireland
            </p>
            <p id="d919">
              Or <a href="/">here</a>.
            </p>
            <h2>Privacy representative for the United Kingdom</h2>
            <p>
              37 Albert Embankment
              <br />
              London SE1 7TL
              <br />
              United Kingdom
            </p>
            <p>
              Or <a href="/">here</a>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
