import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer_inner">
          <div className="footer_link">
            <Link to="/about" onClick={toTop}>
              About
            </Link>
          </div>
          <div className="footer_link">
            <Link to="/privacy" onClick={toTop}>
              Privacy
            </Link>
          </div>
          <div className="footer_link">
            <Link to="/terms" onClick={toTop}>
              Terms
            </Link>
          </div>
          <div className="footer_link">
            <Link to="/speech" onClick={toTop}>
              Text to speech
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
