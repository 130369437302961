import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import Privacy from "./Component/Privacy";
import Terms from "./Component/Terms";
import Code from "./Component/Code";
import Story from "./Component/Story";
import Membership from "./Component/Membership";
import Speech from "./Component/Speech";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<Story />} />
        <Route path="/speech" element={<Speech />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/code/:id" element={<Code />} />
      </Routes>
    </Router>
  );
}

export default App;
