const productData = [
  {
    id: 1,
    name: "Decorator Design Pattern in Java 21",
    pictureTitle: "Nagarjun Nagesh",
    picture:
      "https://miro.medium.com/v2/resize:fill:44:44/1*a8xAy_joAguseEZKgGOWHg.jpeg",
    des: "The Decorator design pattern is a structural design pattern that allows behavior to be added to individual objects, dynamically, without…",
    time: "Jul 18, 2024",
    mainShow:
      "https://miro.medium.com/v2/resize:fit:700/1*CEPE1Yjpi_2fHZJ_RTksVg.jpeg",
    mainDes: [
      "The Decorator design pattern is a structural design pattern that allows behavior to be added to individual objects, dynamically, without affecting the behavior of other objects from the same class. This pattern is useful for adhering to the Single Responsibility Principle, as it allows functionality to be divided between classes with unique areas of concern.",
    ],
    secDesList: {
      title: "Why Use the Decorator Pattern?",
      list: [
        {
          id: 1,
          strong: "Dynamic Behavior:",
          des: "Allows for dynamic addition of responsibilities to objects at runtime.",
        },
        {
          id: 2,
          strong: "Single Responsibility Principle: ",
          des: "Enhances code maintainability by allowing behavior to be divided among classes with unique areas of concern.",
        },
        {
          id: 3,
          strong: "Flexibility: ",
          des: "Provides an alternative to subclassing for extending functionality.",
        },
      ],
    },
  },
  {
    id: 2,
    name: "Caching in Node.js with Redis",
    pictureTitle: "Yasas Sandeepa in Level Up Coding",
    picture:
      "https://miro.medium.com/v2/resize:fill:44:44/1*6sIDd-JeUqbY5frKarTHBw.png",
    des: "How to cache APIs in Node.js —Implementing High-Performance APIs",
    time: "Aug 19, 2024",
    mainShow:
      "https://miro.medium.com/v2/resize:fit:700/1*wHvQFwRx0dT9X9XNqceK1Q.png",
    mainDes: [
      "Application Programming Interfaces (APIs) enable communication between different software components. This API’s performance greatly impacts the user experience.",
      "These should have a high performance and low latency.",
      "In order to achieve a good performance, we need to use some strategies. Caching is one method we can use for this.",
    ],
    secDesList: {
      title: "What is Caching?",
      list: [
        {
          id: 1,
          strong: "",
          des: "Caching involves temporally storing frequently accessed data for future reference.",
        },
        {
          id: 2,
          strong: "",
          des: "Retrieving data from a cache is significantly faster than fetching it from a database or external service on every request.",
        },
        {
          id: 3,
          strong: "",
          des: "This can enhance system performance and reduce response time drastically.",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Understanding the Linux File System Structure",
    pictureTitle: "Karthikeyan Nagaraj in System Weakness",
    picture:
      "	https://miro.medium.com/v2/resize:fill:20:20/1*MQN6JztXVcWsGNXCfJGlgw.gif",
    des: "Linux Essentials by Karthikeyan Nagaraj",
    time: "Sep 19, 2024",
    mainShow:
      "https://miro.medium.com/v2/resize:fit:700/1*n1_pczM_riWurh3Huv7apw.png",
    mainDes: [
      "Linux has a unique file system structure compared to other operating systems like Windows. Everything in Linux is organized in a hierarchical directory structure that starts at the root directory (/). Unlike Windows, where drives like 'C: ' or 'D: ' are used, Linux starts everything from the root and mounts other drives and devices as subdirectories within this hierarchy.",
      "Let’s take a look at some key directories in the Linux file system:",
    ],
    secDesList: {
      title: "Important Directories in Linux",
      list: [
        {
          id: 1,
          strong: "/ (Root):",
          des: "This is the top-most directory in Linux. All other directories branch out from this root directory.",
        },
        {
          id: 2,
          strong: "/home:",
          des: ": This directory stores user-specific files and directories. Each user has a separate directory under /home . For example, if your username is user, your personal files would be in /home/user.",
        },
      ],
    },
  },
];

export default productData;
