import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className={`header__inner ${scrolled ? "scrolled" : ""}`}>
          <div className="header_width_inner">
            <div className="width_bottom">
              <div className="width_bottom_inner">
                <div className="width_bot_log1">
                  <img width="112" height="25" alt="" src="/head/head.png" />
                </div>
                <div className="width_bot_link">
                  <ul>
                    <li>
                      <Link to="/" onClick={toTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={toTop}>
                        Our story
                      </Link>
                    </li>
                    <li>
                      <Link to="/membership" onClick={toTop}>
                        Membership
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
